tr{
    border-style: none !important;
    border-color: transparent!important;

}
aside.active .nav-item:not(.menu){
    width: 100% !important;
}
aside li{
    width: 100% !important;
}